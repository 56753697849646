export const FEATURES_PROVIDER_CHANGE_WINDOW = { height: 660, width: 375 };
export const FEATURES_DEFAULT_WALLET_WINDOW = { height: 740, width: 1315 };
export const FEATURES_DEFAULT_POPUP_WINDOW = { height: 700, width: 1200 };
export const FEATURES_CONFIRM_WINDOW = { height: 700, width: 450 };

export const POPUP_LOADED = "popup_loaded";
export const POPUP_RESULT = "popup_result";

export const SETUP_COMPLETE = "setup_complete";

export const ACTIVITY_ACTION_ALL = "walletActivity.allTransactions";
export const ACTIVITY_ACTION_SEND = "walletActivity.send";
export const ACTIVITY_ACTION_RECEIVE = "walletActivity.receive";
export const ACTIVITY_ACTION_TOPUP = "walletActivity.topup";

export const ACTIVITY_PERIOD_ALL = "walletActivity.all";
export const ACTIVITY_PERIOD_WEEK_ONE = "walletActivity.lastOneWeek";
export const ACTIVITY_PERIOD_MONTH_ONE = "walletActivity.lastOneMonth";
export const ACTIVITY_PERIOD_MONTH_SIX = "walletActivity.lastSixMonts";

export const ACTIVITY_STATUS_SUCCESSFUL = "walletActivity.successful";
export const ACTIVITY_STATUS_UNSUCCESSFUL = "walletActivity.unsuccessful";
export const ACTIVITY_STATUS_PENDING = "walletActivity.pending";
export const ACTIVITY_STATUS_CANCELLED = "walletActivity.cancelled";
export const ACTIVITY_STATUS_CANCELLING = "walletActivity.cancelling";

export const COMMUNICATION_NOTIFICATIONS = {
  IFRAME_STATUS: "iframe_status",

  // Tell embed to create the window
  CREATE_WINDOW: "create_window",
  // Tell embed to close the window
  CLOSE_WINDOW: "close_window",

  USER_LOGGED_IN: "user_logged_in",
  USER_LOGGED_OUT: "user_logged_out",
};

export const COMMUNICATION_JRPC_METHODS = {
  LOGOUT: "logout",
  WALLET_INSTANCE_ID: "wallet_instance_id",
  USER_INFO: "user_info",
  SET_PROVIDER: "set_provider",
  TOPUP: "topup",
  IFRAME_STATUS: "iframe_status",
  // embed has opened the window as requested
  OPENED_WINDOW: "opened_window",
  // user has closed the window from embed's side
  CLOSED_WINDOW: "closed_window",
  GET_PROVIDER_STATE: "get_provider_state",
  LOGIN_WITH_PRIVATE_KEY: "login_with_private_key",
};

export const PROVIDER_JRPC_METHODS = {
  GET_PROVIDER_STATE: "wallet_get_provider_state",
};

export const PROVIDER_NOTIFICATIONS = {
  ACCOUNTS_CHANGED: "wallet_accounts_changed",
  CHAIN_CHANGED: "wallet_chain_changed",
  UNLOCK_STATE_CHANGED: "wallet_unlock_state_changed",
};

export const BROADCAST_CHANNELS = {
  REDIRECT_CHANNEL: "redirect_channel",
  PROVIDER_CHANGE_CHANNEL: "torus_provider_change_channel",
  TRANSACTION_CHANNEL: "torus_channel",
  MESSAGE_CHANNEL: "torus_message_channel",
  WALLET_LOGOUT_CHANNEL: "wallet_logout_channel",
  WALLET_SELECTED_ADDRESS_CHANNEL: "wallet_selected_address_channel",
  WALLET_NETWORK_CHANGE_CHANNEL: "wallet_network_change_channel",
  WALLET_ACCOUNT_IMPORT_CHANNEL: "wallet_account_import_channel",
};
export const BROADCAST_CHANNELS_MSGS = {
  LOGOUT: "logout",
  ACCOUNT_IMPORTED: "account_imported",
  SELECTED_ADDRESS_CHANGE: "selected_address_change",
  NETWORK_CHANGE: "network_change",
};

export type BROADCAST_CHANNELS_MSGS_TYPE = typeof BROADCAST_CHANNELS_MSGS[keyof typeof BROADCAST_CHANNELS_MSGS];
